import default_man_avatar from "./default_man_avatar.jpg";

import {
    faFacebookSquare,
    faLinkedin,
    faInstagram,
    faTwitter
} from "@fortawesome/free-brands-svg-icons";

export const dentistImages = [
    {
        image: default_man_avatar,
        alt: "Fırat OĞUZ",
        fullName: "Fırat OĞUZ",
        social: [
            {
                title: "linkedIn",
                icon: faLinkedin,
                url: ""
            },
            {
                title: "facebook",
                icon: faFacebookSquare,
                url: ""
            },
            {
                title: "instagram",
                icon: faInstagram,
                url: ""
            },
            {
                title: "twitter",
                icon: faTwitter,
                url: ""
            }
        ],
        title: "Diş Hekimi"
    },
    {
        image: default_man_avatar,
        alt: "Yusuf ÖZARSLAN",
        fullName: "Yusuf ÖZARSLAN",
        social: [
            {
                title: "linkedIn",
                icon: faLinkedin,
                url: ""
            },
            {
                title: "facebook",
                icon: faFacebookSquare,
                url: ""
            },
            {
                title: "instagram",
                icon: faInstagram,
                url: ""
            },
            {
                title: "twitter",
                icon: faTwitter,
                url: ""
            }
        ],
        title: "Diş Hekimi"
    },
    {
        image: default_man_avatar,
        alt: "Ensar ÇOBAS",
        fullName: "Ensar ÇOBAS",
        social: [
            {
                title: "linkedIn",
                icon: faLinkedin,
                url: ""
            },
            {
                title: "facebook",
                icon: faFacebookSquare,
                url: ""
            },
            {
                title: "instagram",
                icon: faInstagram,
                url: ""
            },
            {
                title: "twitter",
                icon: faTwitter,
                url: ""
            }
        ],
        title: "Diş Hekimi"
    }
];