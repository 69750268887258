import "./GoogleMap.css";

const GoogleMap = () => {

    return (
        <section id='googleMap'>
            {/* <h1>Vadi Erzurum Ağız ve Diş Sağlığı Polikliniği</h1> */}
            <iframe title='googleMap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d190.61956111490827!2d43.11834506230103!3d40.14422212087657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406bb9b4848589d9%3A0x2cb6b69f57dc1586!2zw5Z6ZWwgVmFkaSBLYcSfxLF6bWFuIEHEn8SxeiB2ZSBEacWfIFNhxJ9sxLHEn8SxIFBvbGlrbGluacSfaQ!5e0!3m2!1str!2str!4v1720466713569!5m2!1str!2str" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </section>

    )
}

export default GoogleMap